/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PUSHER_KEY } from 'src/app/constant/constant';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../authService/auth-service.service';
// import * as PusherPushNotifications from '@pusher/push-notifications-web';
declare const Pusher: any;
@Injectable()
export class PusherServiceProvider {
  channel;
  constructor(
    public http: HttpClient,
    private authService: AuthServiceService
  ) {
    Pusher.logToConsole = !environment.production;
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'mt1',
      encrypted: true,
    });
    const user = this.authService.currentUserValue;
    this.channel = pusher.subscribe('user-' + user.type + '-' + user?.data?.id);
  }
  public init() {
    return this.channel;
  }
}
