/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements  can be found in `angular.json`.

export const environment = {
  production: false,
  version: 'D2.0.67',
  serviceFee: 0.99,
  apiURL: 'https://dev.ygoin.com/',
  eKey: 'yg0in@987_',
  OT_API_KEY: '46365302',
  PUSHER_KEY: 'd705b64edb0ab70481b1',
  firebase: {
    apiKey: 'AIzaSyAaTEdmqIXwoPsI736IPI2tlrPs6L78rIs',
    authDomain: 'ygoin-aa6b4.firebaseapp.com',
    projectId: 'ygoin-aa6b4',
    storageBucket: 'ygoin-aa6b4.appspot.com',
    messagingSenderId: '906380358786',
    appId: '1:906380358786:web:f3bc97f97341bb510d309b',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
