import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from './GUARDS/authGuard/auth-guard.guard';
import { AppointmentComponent } from './organization/my-referrals/appointment/appointment.component';
import { ReferralChatComponent } from './organization/referral-chat/referral-chat.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./web/web-home/webhome.module').then((m) => m.WebHomePageModule),
  },
  {
    path: 'web',
    loadChildren: () =>
      import('./web/web-home/webhome.module').then((m) => m.WebHomePageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'doctors',
    loadChildren: () =>
      import('./web/doctor-list/doctor-list.module').then(
        (m) => m.DoctorListPageModule
      ),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./main/main.module').then((m) => m.MainPageModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'patient-signup',
    loadChildren: () =>
      import('./auth/signup-patient/signup-patient.module').then(
        (m) => m.SignupPatientPageModule
      ),
  },

  {
    path: 'video/:type/:id',
    loadChildren: () =>
      import('./video/video.module').then((m) => m.VideoPageModule),
  },
  {
    path: 'video-call',
    loadChildren: () =>
      import('./video-call/video-call.module').then(
        (m) => m.VideoCallPageModule
      ),
  },
  {
    path: 'end-form',
    loadChildren: () =>
      import('./end-form/end-form.module').then((m) => m.EndFormPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'referral',
    loadChildren: () =>
      import('./web/referral-doc-list/referral-doc-list.module').then(
        (m) => m.ReferralDocListPageModule
      ),
  },
  {
    path: 'send-referral/:id',
    loadChildren: () =>
      import('./web/send-referral/send-referral.module').then(
        (m) => m.SendReferralPageModule
      ),
  },
  {
    path: 'organization-signup',
    loadChildren: () =>
      import('./auth/org-signup/org-signup.module').then(
        (m) => m.OrgSignupPageModule
      ),
  },
  {
    path: 'signup-patient',
    loadChildren: () =>
      import('./auth/signup-patient/signup-patient.module').then(
        (m) => m.SignupPatientPageModule
      ),
  },

  {
    path: 'doctor-signup/:id',
    loadChildren: () =>
      import('./auth/doctor-signup/doctor-signup.module').then(
        (m) => m.DoctorSignupPageModule
      ),
  },
  {
    path: 'invite/:id',
    loadChildren: () =>
      import('./invite/invite.module').then((m) => m.InvitePageModule),
  },
  {
    path: 'document',
    loadChildren: () => import('./document/document/document.module').then( m => m.DocumentPageModule)
  },
  {
    path:'referrals-chat',
    loadChildren: ()=> import('./organization/referral-chat/referrals-chat.module').then(m => m.ReferralsChatModule)
  },
  // {
  //   path: 'referral/doctor/:token',
  //   component:ReferralChatComponent,
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'referral/patient/:token',
  //   component: ReferralChatComponent,
  //   pathMatch: 'full'
  // }
  // {
  //   path: 'referral',
  //   children: [
  //     {
  //       path: 'doctor/:token',
  //       component: ReferralChatComponent,
  //       pathMatch: 'full'
  //     },
  //     {
  //       path: 'patient/:token',
  //       component: ReferralChatComponent,
  //       pathMatch: 'full'
  //     },
  //   ],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
