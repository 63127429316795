/* eslint-disable radix */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import * as printJS from 'print-js';
import * as CryptoJS from 'crypto-js';

import { Clipboard } from '@capacitor/clipboard';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { CallInterfaceComponent } from 'src/app/call-interface/call-interface.component';
import { Howl, Howler } from 'howler';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ALLOWED_ATTACHMENT_TYPES,
  ALLOWED_FILE_SIZE,
  SERVER_URL,
} from 'src/app/constant/constant';
import { LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { constants } from 'os';

declare let require: any;
const FileSaver = require('file-saver');

@Injectable({
  providedIn: 'root',
})
export class CmnServiceService {
  notiUpdated = new BehaviorSubject(null);

  howlerObject: Howl = null;
  howlerId: any;
  callmodal: HTMLIonModalElement;

  constructor(
    private tc: ToastController,
    private loader: LoadingController,
    private toastr: ToastrService,
    private modal: ModalController,
    private spinner: NgxSpinnerService,
    private navCtrl: NavController,
    private http: HttpClient,
    private locationStrategy: LocationStrategy
  ) {}

  decrypt(data) {
    if (data) {
      return atob(data);
    }
    return '';
  }

  // encryptAES(strToEncrypt) {
  //   return CryptoJS.AES.encrypt(strToEncrypt, environment.eKey, {
  //     mode: CryptoJS.mode.CTR,
  //     hmac: false,
  //   }).toString();
  // }

  // decryptAES(strToDecrypt) {
  //   const iv = CryptoJS.enc.Hex.parse('1234567890111213');
  //   return CryptoJS.AES.decrypt(strToDecrypt, 'ygoin_', {
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.NoPadding,
  //     blockSize: 256 / 32,
  //   }).toString(CryptoJS.enc.Utf8);
  // }

  /**
   * Encrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @param string string The original string to be encrypt.
   * @param string key The key.
   * @return string Return encrypted string.
   */
  encryptAES(string) {
    const iv = CryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.

    const salt = CryptoJS.lib.WordArray.random(256);
    const iterations = 999;
    const encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(environment.eKey, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations,
    });

    const encrypted = CryptoJS.AES.encrypt(string, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv,
    });
    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    const output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations,
    };

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    );
  } // encrypt

  decryptAES(encryptedString) {
    const json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    );

    const salt = CryptoJS.enc.Hex.parse(json.salt);
    const iv = CryptoJS.enc.Hex.parse(json.iv);

    const encrypted = json.ciphertext; // no need to base64 decode.

    let iterations = parseInt(json.iterations);
    if (iterations <= 0) {
      iterations = 999;
    }
    const encryptMethodLength = this.encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
    const hashKey = CryptoJS.PBKDF2(environment.eKey, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations,
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } // decrypt

  get encryptMethodLength() {
    const encryptMethod = this.encryptMethod;
    // get only number from string.
    // @link https://stackoverflow.com/a/10003709/128761 Reference.
    const aesNumber = encryptMethod.match(/\d+/)[0];
    return parseInt(aesNumber);
  }

  get encryptMethod() {
    return 'AES-256-CBC';
  } // encryptMethod

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  encrypt(data) {
    if (data) {
      return btoa(data);
    }
    return '';
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    //const pdfUrl = './assets/sample.pdf';
    //const pdfName = 'your_pdf_file';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  time_ago(time) {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = new Date(time + ' GMT');
        break;
      case 'object':
        if (time.constructor === Date) {
          time = time.getTime();
        }
        break;
      default:
        time = +new Date();
    }
    const time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000;
    let token = 'ago';
    let list_choice = 1;

    if (seconds === 0) {
      return 'Just now';
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    let i = 0;
    let format;
    while ((format = time_formats[i++])) {
      if (seconds < format[0]) {
        if (typeof format[2] == 'string') {
          return format[list_choice];
        } else {
          if (Math.floor(seconds / format[2]) === 0) {
            return 'Just now';
          } else {
            return (
              Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
            );
          }
        }
      }
    }
    return time;
  }

  serverTimeAgo(str) {
    return str.split(',')[0] + ' ago';
  }

  playSound(path, loop = false) {
    this.howlerObject = new Howl({
      src: [path],
      autoplay: false,
      loop,
      onend: () => {},
    });

    this.howlerId = this.howlerObject.play();
  }

  stopSound() {
    this.howlerObject.unload();
    this.howlerObject = null;
  }

  uploadFileValidation(fileType, fileSize) {
    const isImage = ALLOWED_ATTACHMENT_TYPES.filter(
      (type) => fileType.indexOf(`${type}`) !== -1
    );

    if (isImage.length !== 0) {
      if (fileSize <= ALLOWED_FILE_SIZE) {
        return true;
      } else {
        this.showWarning('Please Your File Should be 10 Megabytes or Less');

        return false;
      }
    } else {
      this.showWarning(
        'Please make sure to upload valid Image,video or Document File'
      );
      return false;
    }
  }
  sendPusher(channel, event, data) {
    const tData = {
      channel,
      event,
      data,
    };

    this.http
      .post(SERVER_URL + 'v2/test_pusher', tData)
      .subscribe((res: any) => {});
  }

  printPdf(url) {
    printJS({ printable: url, type: 'pdf', showModal: true });
  }

  getExtension(filename) {
    return filename.split('.').pop();
  }
  async copyToClipboard(text) {
    await Clipboard.write({
      string: text,
    });

    this.showSuccess('Copied');
  }

  async showLoader(msg?, customClass?) {
    // const loading = await this.loader.create({
    //   cssClass: customClass ? customClass : '',
    //   message: msg ? msg : 'Loading...',
    // });
    // await loading.present();
    this.spinner.show();
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async showCallDialog(data) {
    if (this.callmodal) {
      return;
    }
    this.callmodal = await this.modal.create({
      component: CallInterfaceComponent,
      cssClass: 'call-interface',
      swipeToClose: false,
      backdropDismiss: false,
      presentingElement: await this.modal.getTop(), // Get the top-most ion-modal
      componentProps: {
        callDetail: data,
      },
    });
    if (!this.howlerObject) {
      this.playSound('assets/skype-23266.mp3', true);
    }

    this.callmodal.onDidDismiss().then((res: any) => {
      this.stopSound();
      this.callmodal = null;
      if (res?.role === 'dismiss') {
        //send dismiss pusher
      } else if (res?.role === 'consultation-accept') {
        // navigate to video page
        this.hideCallModal();

        let params = new HttpParams();
        params = params.set('vdetail', JSON.stringify(res?.data));

        window.open(
          window.location.origin + '/video-call?' + params.toString(),
          '_blank'
        );

        // this.navCtrl.navigateForward('main/video-call', {
        //   queryParams: { vdetail: JSON.stringify(res?.data) },
        // });
      }
    });
    return await this.callmodal.present();
  }

  hideCallModal() {
    if (this.callmodal) {
      this.callmodal.dismiss();
    }
  }

  hideLoader() {
    // if (this.loader.getTop()) {
    //   this.loader.dismiss();
    // }
    // this.loader.getTop().then((v) => (v ? this.loader.dismiss() : null));

    this.spinner.hide();
  }

  async showSuccess(msg) {
    // const toast = await this.tc.create({
    //   message: msg,
    //   duration: 2000,
    //   color: 'success',
    // });
    // toast.present();
    this.toastr.success(msg);
  }

  async showError(err) {
    // const toast = await this.tc.create({
    //   message: err,
    //   duration: 2000,
    //   color: 'danger',
    // });
    // toast.present();
    this.toastr.error(err);
  }
  async showWarning(msg) {
    // const toast = await this.tc.create({
    //   message: msg,
    //   duration: 2000,
    //   color: 'warning',
    // });
    // toast.present();
    this.toastr.warning(msg);
  }
  async showInfo(msg) {
    // const toast = await this.tc.create({
    //   message: msg,
    //   duration: 2000,
    //   color: 'warning',
    // });
    // toast.present();
    this.toastr.info("info",msg,{timeOut:2000});
  }

  presentToast(msg) {
    this.toastr.error(msg);
  }
}
