import { environment } from 'src/environments/environment';

/* eslint-disable max-len */
export const SERVER_URL = environment.apiURL;

//export const APP_VERSION = '1.0.27';
export const APP_VERSION = environment.version;

//export const OT_API_KEY = '46365302';//dev
export const OT_API_KEY = environment.OT_API_KEY;

//export const PUSHER_KEY = 'd705b64edb0ab70481b1';
export const PUSHER_KEY = environment.PUSHER_KEY;

//export const PUSHER_PN_INSTANCED = '122fe19a-3fec-4ff4-86ae-17361f9acd46';

export const DOCTOR_SIGNUP_AMOUNT = 250;
export const ORG_SIGNUP_AMOUNT = 499;

export const MOBILE_NUMBER_PATTERN =
  '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{2,3})';

export const EMPTY_TEXT = 'N/A';

export const PATIENT_RECORD_PER_PAGE = 10;
export const DOCTOR_RECORD_PER_PAGE = 10;
export const NOTIFICATION_PER_PAGE = 10;

export const ALLOWED_FILE_SIZE = 10000000;

export const ALLOWED_ATTACHMENT_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/svg+xml',
  'image/png',
  'application/pdf',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats',
  'application/vnd.oasis.opendocument.text',
  'image/tiff',
  'text/plain',
  'image/gif',
  'audio/mpeg',
  'audio/mp4',
  'audio/x-wav',
  'audio/wav',
  'video/mp4',
  'video/x-msvideo',
  'video/x-matroska',
  'image/mov',
  'video/x-m4v',
  'application/zip',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'video/avi',
  'video/quicktime',
  'application/x-zip-compressed',
];

export const title = 'Get Professional Advice';
export const description = 'Anytime. Anywhere.';
