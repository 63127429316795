/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError, retry, switchMap, tap, timeout } from 'rxjs/operators';

import { NavController } from '@ionic/angular';
import { AuthServiceService } from '../SERVICE/authService/auth-service.service';
import { CmnServiceService } from '../SERVICE/cmnService/cmn-service.service';
import { Router } from '@angular/router';
import { ToastServiceService } from '../SERVICE/toastService/toast-service.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  request: HttpRequest<any>;
  constructor(
    private authService: AuthServiceService,
    private navCtrl: NavController,
    private cmnService: CmnServiceService,
    private toast: ToastServiceService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //console.warn('interceptor invoked...');

    const currentUser = this.authService.currentUserValue;

    if (currentUser && currentUser.authorization) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.authorization}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }

    if (request.url !== environment.apiURL + 'v3/refresh_auth_token') {
      this.request = request;
    }

    return next.handle(request).pipe(
      /* to retry and timeout
     retry(2),
     timeout(100),  */

      tap((event: HttpEvent<any>) => {
        if (
          event instanceof HttpResponse &&
          (event.status === 200 || event.status === 201)
        ) {
        }
      }),
      catchError((errResponse: HttpErrorResponse) => {
        if (errResponse.status === 401) {
          // localStorage.clear();
          // this.authService.currentUserSubject.next(null);
          // this.navCtrl.navigateRoot('/login');
          // this.cmnService.showError('Session Expired, Please login again.');

          return this.authService.refreshToken().pipe(
            switchMap(() => {
              const newUser = this.authService.currentUserValue;

              if (newUser && newUser?.authorization) {
                request = request.clone({
                  setHeaders: {
                    Authorization: `${newUser.authorization}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                  },
                });
              } else {
                request = request.clone({
                  setHeaders: {
                    accept: 'application/json',
                  },
                });
              }
              return next.handle(request);
            })
          );
        }
        const err = errResponse?.error?.error;
        return throwError(errResponse);
      })
    );
  }
}
