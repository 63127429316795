import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SERVER_URL } from '../constant/constant';
import { AuthServiceService } from '../SERVICE/authService/auth-service.service';

@Component({
  selector: 'app-call-interface',
  templateUrl: './call-interface.component.html',
  styleUrls: ['./call-interface.component.scss'],
})
export class CallInterfaceComponent implements OnInit {
  @Input() callDetail: any;
  user: any;
  constructor(
    private modal: ModalController,
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  ngOnInit() {
    this.user = this.authService.currentUserValue;
  }

  onCutClick() {
    this.sentCutPusher();
    this.modal.dismiss(this.callDetail, 'dismiss');
  }

  onAcceptClick() {
    this.modal.dismiss(this.callDetail, 'consultation-accept');
  }

  sentCutPusher() {
    if (this.user?.type === 'patient') {
      const channel = ['user-doctor-' + this.callDetail?.doctor_id];
      const event = 'call-cut-by-patient';
      this.sendPusher(channel, event);
    }
  }

  sendPusher(channel, event) {
    const tData = {
      channel,
      event,
      data: '',
    };

    this.http
      .post(SERVER_URL + 'v2/test_pusher', tData)
      .subscribe((res: any) => {});
  }
}
