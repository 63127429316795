import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorInterceptor } from './interceptors/http-interceptor.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuardGuard } from './GUARDS/authGuard/auth-guard.guard';
import { NgCalendarModule } from 'ionic2-calendar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PusherServiceProvider } from './SERVICE/pusherService/pusher-service.service';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { ReferralChatComponent } from './organization/referral-chat/referral-chat.component';
import { AddProviderComponent } from './organization/add-provider/add-provider.component';
import { ProviderListModule } from './organization/provider-list/provider-list.module';
import { AddSubscriptionComponent } from './organization/add-subscription/add-subscription.component';
import { SharedModule } from "./shared/shared.module";

@NgModule({
    declarations: [AppComponent, SidebarComponent, PaymentModalComponent, AddProviderComponent, AddSubscriptionComponent
    ],
    entryComponents: [],
    providers: [
        AuthGuardGuard,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorInterceptor,
            multi: true,
        },
        PusherServiceProvider,
    ],
    exports: [NgCalendarModule, ReactiveFormsModule],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        FormsModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        ReactiveFormsModule,
        HttpClientModule,
        NgCalendarModule,
        AppRoutingModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        MatRadioModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        NgbModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AngularFireMessagingModule,
        NgxSpinnerModule,
        SharedModule
    ]
})
export class AppModule {}
