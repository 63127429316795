/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable id-blacklist */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable prefer-const */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import * as moment from 'moment';

export interface CalendarDay {
  number: number;
  name: string;
  date: Date;
  moment: moment.Moment;
}
export function compareCalendarDays(day1: CalendarDay, day2: CalendarDay) {
  return (
    day1 &&
    day2 &&
    day1.moment &&
    day2.moment &&
    day1.moment.isSame(day2.moment)
  );
}

@Component({
  selector: 'calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalenderComponent implements OnInit {
  days = [];
  @Input() minDate: moment.MomentInput;
  private dayActive: string = '';
  @Input() dayCount = 7;
  @Input() daysToExclude = (day: CalendarDay) =>
    day.date.getDay() === 6 ||
    day.date.getDay() === 0 ||
    moment(day.date).isBefore(this.minDate);
  currentSelectedDay: CalendarDay;

  @Output() onDateClick = new EventEmitter();

  private get localeData(): moment.Locale {
    return moment.localeData();
  }

  @Input() dates = [];

  compareDays = compareCalendarDays;

  content;
  monthSelected;

  get renderDays() {
    return Array(this.dayCount)
      .fill(this.dayCount)
      .map((_, i) => this.generateCalendarDay(this.firstDayRendered, i));
  }

  firstDayRendered: CalendarDay;
  private initialDateInternal = new Date();

  constructor() {
    //this.generateDays();
  }

  ngOnInit() {
    let firstDayOfTheWeek = moment(this.initialDateInternal).weekday(0);
    this.firstDayRendered = this.generateCalendarDay(firstDayOfTheWeek);
    let calendarDayToday = this.generateCalendarDay(this.initialDateInternal);
    this.selectDay(calendarDayToday);
    this.monthSelected = new Date().toISOString();
  }

  generateDays() {
    let now = moment().add(-1, 'd');
    for (let i = 0; i < 7; i++) {
      now.add(1, 'd');

      this.days.push({
        month: now.format('MMM'),
        day: now.format('DD'),
        dayname: now.format('ddd'),
      });
    }

    this.checkDay(this.days[0]);
  }

  checkDay(day: any) {
    this.dayActive = this.getStrDay(day);
  }

  checkDayOffset(day) {
    let date = moment(day?.date).format('YYYY-MM-DD');

    return this.dates.includes(date);
  }

  getStrDay(day: any) {
    return `${day.month}-${day.day}-${day.dayname}`;
  }

  swipeMove(event) {}

  swipeEnd() {}

  selectDay(day: CalendarDay): void {
    this.currentSelectedDay = day;
    this.onDateClick.emit(day);
  }

  generateCalendarDay(
    date: moment.MomentInput | CalendarDay,
    daysToAdd?: number
  ): CalendarDay {
    let effectiveDate = this.isCalendarDay(date)
      ? moment(date.moment)
      : moment(date);
    // Apply days delta to date we're outputting
    if (daysToAdd > 0) {
      effectiveDate = effectiveDate.add(daysToAdd, 'days');
    } else if (daysToAdd < 0) {
      effectiveDate = effectiveDate.subtract(-daysToAdd, 'days');
    }

    return {
      number: effectiveDate.date(),
      name: this.localeData.weekdaysShort(effectiveDate),
      date: effectiveDate.toDate(),
      moment: effectiveDate,
    };
  }

  generateCalendarWeekly(
    date: moment.MomentInput | CalendarDay,
    weekToAdd?: number
  ): CalendarDay {
    let effectiveDate = this.isCalendarDay(date)
      ? moment(date.moment)
      : moment(date);
    // Apply days delta to date we're outputting
    if (weekToAdd > 0) {
      effectiveDate = effectiveDate.add(weekToAdd, 'weeks');
    } else if (weekToAdd < 0) {
      effectiveDate = effectiveDate.subtract(-weekToAdd, 'weeks');
    }

    this.monthSelected = effectiveDate.toISOString();

    return {
      number: effectiveDate.date(),
      name: this.localeData.weekdaysShort(effectiveDate),
      date: effectiveDate.toDate(),
      moment: effectiveDate,
    };
  }

  isCalendarDay(object: any): object is CalendarDay {
    return (
      object && object.date && object.number && object.name && object.moment
    );
  }

  next() {
    this.firstDayRendered = this.generateCalendarWeekly(
      this.firstDayRendered,
      1
    );
  }

  prev() {
    this.firstDayRendered = this.generateCalendarWeekly(
      this.firstDayRendered,
      -1
    );
  }
}
