/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { SERVER_URL } from 'src/app/constant/constant';
import { CmnServiceService } from 'src/app/SERVICE/cmnService/cmn-service.service';
import { NameValidator } from 'src/app/shared/validator/name.validation';
import { RES_INSURANCES } from 'src/app/web/modal';
import { environment } from 'src/environments/environment';

export enum PLAN_TYPE {
  MONTHLY = 1,
  ANNUALLY = 2,
}

export enum ADD_ON_TYPE {
  Recurring = '1',
  OneTime = '0',
}
@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss'],
})
export class AddSubscriptionComponent implements OnInit {






// buy component

  licenseCount = 1;
  showLoader = false;
  price: number = 0;
  checkoutForm: FormGroup;
  initialLicenseCount = 1;
  @Input() orgId: string;
  constructor(
    public modal: ModalController,
    private http: HttpClient,
    private cmnService: CmnServiceService
  ) {}

  ngOnInit() {
    this.getOrgSeats(this.orgId)
    this.getPackagePrices();
    this.checkoutForm = new FormGroup({
      creditCardNumber: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
    });
  }

  getOrgSeats(id) {
    let data = {
      org_id: Number(id),
    };
    this.showLoader = true;

    this.http.post(SERVER_URL + 'v2/get_org_seats', data).subscribe(
      (res: any) => {
        console.log(res)
        this.showLoader = false;

        this.licenseCount = Number(res?.response?.data?.membershipCount);
        this.initialLicenseCount = Number(res?.response?.data?.membershipCount);
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }

  getPackagePrices() {
    this.http.get(SERVER_URL + 'v2/packages').subscribe(
      (res) => {
        this.price = Number(res[0]?.price_add_license_monthly);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onRemoveLicenseClick() {
    this.licenseCount = this.licenseCount - 1;
  }

  get CkForm() {
    return this.checkoutForm.controls;
  }

  onAddLicenseClick() {
    this.licenseCount = this.licenseCount + 1;
  }

  buySeats() {
    let data = {
      org_id: Number(this.orgId),
      card_number: this.checkoutForm.value.creditCardNumber,
      cvc: this.checkoutForm.value.cvv,
      month: this.checkoutForm.value.month,
      year: this.checkoutForm.value.year,
      seats: this.licenseCount - this.initialLicenseCount,
    };

    this.showLoader = true;

    this.http.post(SERVER_URL + 'v2/buy_seats', data).subscribe(
      (res) => {
        this.showLoader = false;
        this.cmnService.showSuccess('Buy Seats Successfully');
        this.modal.dismiss('updated');
      },
      (err) => {
        this.showLoader = false;

        this.cmnService.showError(err?.error?.message);
      }
    );
  }


}
