/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SERVER_URL } from './constant/constant';
import { AuthServiceService } from './SERVICE/authService/auth-service.service';
import { CmnServiceService } from './SERVICE/cmnService/cmn-service.service';
import { PresenceService } from './SERVICE/presenceService/presence-service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isProd = environment.production;
  version = environment.version;
  constructor(
    private afMessaging: AngularFireMessaging,
    private swPush: SwPush,
    private seUpdate: SwUpdate,
    private authService: AuthServiceService,
    private http: HttpClient,
    private presenceService: PresenceService,
    private cmnService: CmnServiceService
  ) {
    this.requestForPushNotificationPermission();
  }
  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.authService.anonymousLogin();
    }
    this.registerNotification();
    this.seUpdate.available.subscribe((res: any) => {
      if (
        confirm('New version is available, do you want to reload to update?')
      ) {
        this.seUpdate.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  registerNotification() {
    this.afMessaging.messages.subscribe((payload) => {});

    this.afMessaging.onMessage((payload) => {});
  }
  requestForPushNotificationPermission() {
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => {
          // USER-REQUESTED-TOKEN
          if (token) {
            this.authService.setUserToken(token);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
