import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastServiceService {
  constructor(private toastr: ToastrService) {}

  showSuccess(msg) {
    this.toastr.success('Success!', msg);
  }

  showError(msg) {
    this.toastr.error('Oops!', msg);
  }
}
