/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
  paymentForm: FormGroup;
  @Input() amount;
  constructor(private modalCtrl: ModalController) {
    this.paymentForm = new FormGroup({
      creditCardNumber: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {}

  onPayment() {
    this.modalCtrl.dismiss(this.paymentForm.value, 'data');
  }

  get PayForm() {
    return this.paymentForm.controls;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
