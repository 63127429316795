/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_URL } from 'src/app/constant/constant';
import { catchError, map } from 'rxjs/operators';
import { CmnServiceService } from '../cmnService/cmn-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public currentUserProfile: BehaviorSubject<string>;
  authState: any = null;
  constructor(
    private http: HttpClient,
    private cmnService: CmnServiceService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private afMessaging: AngularFireMessaging
  ) {
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });
    const user = localStorage.getItem('cu_');
    const profile = localStorage.getItem('cu_p');
    this.currentUserProfile = new BehaviorSubject(profile);
    if (user) {
      this.currentUserSubject = new BehaviorSubject<any>(
        JSON.parse(this.cmnService.decrypt(user))
      );
      this.currentUser = this.currentUserSubject.asObservable();
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(null);
    }
  }

  public get currentUserValue() {
    if (this.currentUserSubject) {
      return this.currentUserSubject?.value;
    }
  }

  get isUserAnonymousLoggedIn(): boolean {
    return this.authState !== null ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return this.authState !== null ? this.authState.uid : '';
  }

  anonymousLogin() {
    return this.afAuth
      .signInAnonymously()
      .then((user) => {
        this.authState = user;
      })
      .catch((error) => {});
  }

  signOutFromFirebase(): void {
    this.afAuth.signOut().then(
      (res: any) => {},
      (err) => {}
    );
  }

  public get currentUserProfileValue() {
    return this.currentUserProfile ? this.currentUserProfile?.value : '';
  }

  login(loginData, redirect = true) {
    return this.http.post(SERVER_URL + 'v3/login', loginData).pipe(
      map(
        (res: any) => {
          const loginResponse = JSON.parse(this.cmnService.decryptAES(res.rs));

          console.log('Login Res :- ', loginResponse);
          if (loginResponse && loginResponse?.type && loginResponse.success) {
            this.requestForPushNotificationPermission();
            this.anonymousLogin();
            localStorage.setItem(
              'cu_',
              this.cmnService.encrypt(JSON.stringify(loginResponse))
            );
            localStorage.setItem('user_temp', JSON.stringify(loginResponse));

            if (loginResponse?.type === 'organization') {
              localStorage.setItem(
                'orgProfile',
                loginResponse?.orgDetail?.profile_pic
              );
              localStorage.setItem(
                'operatorProfile',
                loginResponse?.operator?.profile_pic
              );
              this.currentUserProfile.next(
                loginResponse?.operator?.profile_pic
              );
              localStorage.setItem(
                'operator',
                JSON.stringify(loginResponse?.operator)
              );
              localStorage.setItem(
                'cu_p',
                JSON.stringify(loginResponse?.operator)
              );

              localStorage.setItem(
                'org',
                JSON.stringify(loginResponse?.orgDetail)
              );
            }

            if (loginResponse?.type === 'doctor') {
              localStorage.setItem(
                'docProfile',
                loginResponse?.data?.profile_picture
              );
              localStorage.setItem(
                'cu_p',
                loginResponse?.data?.profile_picture
              );
              this.currentUserProfile.next(
                loginResponse?.data?.profile_picture
              );
              localStorage.setItem(
                'doctor',
                JSON.stringify(loginResponse?.data)
              );
            }

            if (loginResponse?.type === 'patient') {
              localStorage.setItem(
                'patientProfile',
                loginResponse?.data?.profile_picture
              );
              localStorage.setItem(
                'cu_p',
                loginResponse?.data?.profile_picture
              );
              this.currentUserProfile.next(
                loginResponse?.data?.profile_picture
              );
            }

            this.currentUserSubject.next(loginResponse);
            if (redirect) {
              this.router.navigateByUrl('main');
            }
          }

          return loginResponse;
        },
        (err) => {
          console.error('Error while login :- ', err);
          return err;
        }
      )
    );
  }

  refreshToken() {
    const user = this.currentUserValue;
    if (!user) {
      this.logout();
      return;
    }
    const data = {
      auth_token: user?.authorization,
      refresh_token: user?.refresh_authorization,
    };
    return this.http
      .post(environment.apiURL + 'v3/refresh_auth_token', data)
      .pipe(
        catchError((err) => {
          this.logout();
          throw new Error(err);
        }),
        map(
          (res: any) => {
            const newUser = JSON.parse(
              this.cmnService.decrypt(localStorage.getItem('cu_'))
            );
            newUser.authorization = res?.new_token;
            localStorage.setItem(
              'cu_',
              this.cmnService.encrypt(JSON.stringify(newUser))
            );
            this.currentUserSubject.next(newUser);
            return res;
          },
          (err) => err
        )
      );
  }

  requestForPushNotificationPermission() {
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => {
          // USER-REQUESTED-TOKEN
          if (token) {
            this.setUserToken(token);
          }
          if (!environment.production) {
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setUserToken(token: string) {
    if (this.currentUserValue) {
      const data = {
        user_id: this.currentUserValue.data?.id,
        user_type: this.currentUserValue?.type,
        token,
      };

      this.http.post(SERVER_URL + 'v2/store_user_token', data).subscribe(
        (res: any) => {},
        (err) => {}
      );
    }
  }

  logout() {
    localStorage.removeItem('cu_');
    localStorage.removeItem('user_temp');
    localStorage.removeItem('orgProfile');
    localStorage.removeItem('operatorProfile');
    localStorage.removeItem('docProfile');
    localStorage.removeItem('doctor');
    localStorage.removeItem('operator');
    this.currentUserSubject.next(null);
    setTimeout(() => {
      this.signOutFromFirebase();
    }, 1000);
    this.router.navigateByUrl('login');
  }
}
