/* eslint-disable no-bitwise */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PresenceService } from 'src/app/SERVICE/presenceService/presence-service.service';

@Component({
  selector: 'app-profile-img',
  templateUrl: './p-img.page.html',
  styleUrls: ['./p-img.page.scss'],
})
export class PImgPage implements OnInit {
  @Input() pName = 'Not Available';

  @Input() pUrl: string;
  @Input() pClass: string;
  @Input() id: any;
  @Input() type: any;
  @Output() onProfileClick = new EventEmitter();
  presence$: any;
  constructor(private presenceService: PresenceService) {}

  ngOnInit() {
    if (!this.pName) {
      this.pName = 'Not Available';
    }

    if (this.id && this.type) {
      this.presence$ = this.presenceService.getPresence(this.type, this.id);
    }
  }

  stringToHslColor() {
    let hash = 0;
    for (let i = 0; i < this.pName?.length; i++) {
      hash = this.pName?.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = hash % 360;
    return 'hsl(' + h + ', ' + 50 + '%, ' + 50 + '%)';
  }

  getInitials() {
    const names = this.pName?.split(' ');
    let initials = names[0]?.substring(0, 1)?.toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
    }
    return initials;
  }

  onClick() {
    this.onProfileClick.emit({
      name: this.pName,
      url: this.pUrl,
      class: this.pClass,
    });
  }
}
